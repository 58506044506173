import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.scss"

export default () => (
    <Layout>
      <Seo title="Home" />
      <div className={styles.textCenter}>
        <h1 className="text-3xl font-bold underline">
          About
        </h1>
      </div>
    </Layout>
  )
